.wrapper{
  display: grid;
  grid-template-columns: 1fr auto;
  gap: clamp(24px, calc(24vw/7.68), 32px);

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }

  .content{
    padding: 24px clamp(16px, calc(16vw/7.68), 28px);
    border-radius: 32px;
    border: 1px solid var(--light-blue-500, #0376BE);
    background: var(--light-blue-100, #F8FCFF);
    box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);

    >*{
      max-width: 585px;
    }

    h2{
      margin-bottom: 24px;
    }
  }
}