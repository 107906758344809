.wrapper {
  border-radius: 32px;
  min-height: 224px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  text-align: center;
  transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background: var(--light-blue-100, #f8fcff);

  &.linked {
    position: relative;

    .link {
      position: absolute;
      inset: 0;
      display: block;
      user-select: none;
      -webkit-user-drag: none;
      border-radius: 32px;
      z-index: 2;
    }

    svg{
      transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      transform-origin: center;
    }

    &:active {
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25) inset;
    }

    &:hover{
      svg{
        transform: scale(1.3);
      }

      p{
        font-weight: 500;
      }

      img{
        transform: scale(0.9);
      }
    }
  }

  img {
    width: 60px;
    height: fit-content;
    transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  p {
    max-width: 300px;
    transition: all .3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &.blue {
    border: 2px solid var(--light-blue-500, #0376be);
  }

  &.green {
    border: 2px solid var(--green-500, #03be86);
  }

  &.yellow {
    border: 2px solid var(--yellow-500, #f9bc41);
  }
}
